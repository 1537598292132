import './App.css';
import React from 'react';
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import Dashboard from "./components/dashboard";
import Login from "./components/login";

function App() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
                <Route path='/' element={<Dashboard />}/>
                <Route path="/login" element={<Login />} />
            </Route>
        )
    );
    return <RouterProvider router={router} />
}

export default App;
