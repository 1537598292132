import React, {useEffect, useState} from 'react';
import {
    Avatar,
    createTheme,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import axios from "axios";
import logo from "../ip.png";
import {useStore} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";

export default function Favorites() {
    const navigate = useNavigate();
    const store = useStore();
    const [searchParams] = useSearchParams();

    const [favorites, setFavoriteState] = useState([]);

    useEffect(() => {
        getFavorite();
    }, []);

    const getFavorite = async () => {
        let token =  store.getState().user.token || searchParams.get('token')
        await axios.get(process.env.REACT_APP_BACK_API+'/songs/favorites', {
            headers: {
                Authorization: 'Bearer '+token
            }
        }).then((resp) => {
            const allSongs = resp.data.data;
            setFavoriteState(allSongs);
        }).catch((e) => {
            if (e.response.status === 401 || e.response.status === 403) {
                navigate('/login?table='+searchParams.get('table')+'&token=')
            }
        });
    }
    return (
        <List dense>
            {favorites.map((value, i) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                    <ListItem
                        key={i}
                        // secondaryAction={
                        //     <Checkbox
                        //         edge="end"
                        //         onChange={handleToggle(value)}
                        //         checked={!!checked.find((item) => item.id === value.id)}
                        //         inputProps={{'aria-labelledby': labelId}}
                        //     />
                        // }
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemAvatar>
                                <Avatar
                                    alt={value.singer}
                                    src={logo}
                                />
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={value.singer} secondary={value.song}/>
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}