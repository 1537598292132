import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box, Button, Checkbox,
    Container, createTheme, CssBaseline,
    Grid, Link,
    TextField,
    ThemeProvider, Typography
} from "@mui/material";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import {useNavigate, useSearchParams} from "react-router-dom";
import baseLogo from "../logo.png";
import {useDispatch, useStore} from "react-redux";
import {setTable, setToken} from "../store/user";

const defaultTheme = createTheme();

export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const store = useStore();
    const [searchParams] = useSearchParams();

    const [alert, setAlert] = useState([]);
    const [checked, setChecked] = useState(false);
    const [loggin, setLoggin] = useState(null);
    const [stateTable, setStateTable] = useState(null);
    const [stateToken, setStateToken] = useState(null);

    useEffect(() => {
        const table = searchParams.get("table") || stateTable;
        dispatch(setTable(table));
        setStateTable(table);
        if (loggin || stateToken) {
            navigate("/?table="+table+'&token='+stateToken);
        }
        if (store.getState().user.token) {
            navigate("/?table="+table+'&token='+store.getState().user.token);
        } else {
            dispatch(setToken(null));
        }
    }, [loggin]);

    useEffect(() => {
        setTimeout(() => setAlert([]), 6000)
    }, [alert.length]);

    const handleSubmit = (event) => {
        event.preventDefault();
        let data = new FormData(event.currentTarget);
        data = {
            email: data.get('email'),
            name: data.get('name'),
            phone: data.get('phone'),
            password: data.get('password'),
        };
        if (data.name && data.email && data.phone && data.password) {
            login(data).then(() => {
                setLoggin(Math.random())
            });
        }  else {
            setAlert({text: "Все поля должны быть заполнены", type: 'error'})
        }
    };

    const login = async (data) => {
        await axios.post(process.env.REACT_APP_BACK_API+'/login', data).then((resp) => {
            dispatch(setToken(resp.data?.access_token));
            setStateToken(resp.data?.access_token);
        }).catch((err) => {
            console.error(err);
            setAlert({text: 'Произошла ошибка на сервере, повторите запрос или обратитесь к администратору', type: 'error'})
        });
    };

    return(
        <ThemeProvider theme={defaultTheme}>
            <img style={{width: '-webkit-fill-available', maxWidth: '500px', margin: '0 auto', display: 'block'}} src={baseLogo} alt="logo"/>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Авторизация
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="name"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Имя"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Телефон"
                                    name="phone"
                                    autoComplete="phone"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Придумайте пароль мин 2 символа"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Checkbox name="checked" value={{checked}} onClick={(e, val) => setChecked(e.target.value)} style={{height: '10px'}}></Checkbox>
                                </Grid>
                                <Grid item>
                                    <Link href="https://dachaizumrud.ru/policy" target='blanc' variant="body2">
                                        Пользователькое соглашение
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                        {alert.length !== 0 && <div>
                            <Alert icon={<CheckIcon fontSize="inherit"/>} severity={alert.type}>
                                {alert.text}
                            </Alert>
                        </div>}
                        <Button
                            type="submit"
                            fullWidth
                            disabled={alert.length !== 0 || !checked}
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Войти
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}