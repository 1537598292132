import { createSlice } from '@reduxjs/toolkit'

export const price = createSlice({
    name: 'price',
    initialState: {
        microPrice: [],
        rentPrice: [],
        coffeePrice: [],
    },
    reducers: {
        setMicroPrice: (state, action) => {
            state.microPrice = action.payload;
        },
        setRentPrice: (state, action) => {
            state.rentPrice = action.payload
        },
        setCoffeePrice: (state, action) => {
            state.coffeePrice = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setMicroPrice,
    setRentPrice,
    setCoffeePrice,
} = price.actions

export default price.reducer