import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    Divider,
    Stack,
    Typography
} from "@mui/material";
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import {useDispatch} from "react-redux";
import {setCoffeePrice, setMicroPrice, setRentPrice} from "../store/price";

export default function Price() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [prices, setPrices] = useState([]);
    const [microState, setMicroState] = useState([]);
    const [rentState, setRentState] = useState([]);
    const [coffeeState, setCoffeeState] = useState({});
    useEffect(() => {
        getPrice();
    }, []);

    useEffect(() => {
        dispatch(setMicroPrice(prices.micro))
        dispatch(setRentPrice(prices.rent))
        dispatch(setCoffeePrice(prices.coffee))
    }, [prices.length]);

    const getPrice = async () => {
        const token =  searchParams.get('token')
        await axios.get(process.env.REACT_APP_BACK_API+'/price', {
            headers: {
                Authorization: 'Bearer '+token
            }
        }).then((resp) => {
            const data = resp.data;

            setPrices(data);
            Object.keys(data).forEach((key) => {
                changePriceState(key, null, data)
            })
        }).catch((e) => {
            if (e.response && (e.response.status === 401 || e.response.status === 403)) {
                navigate('/login?table='+searchParams.get('table')+'&token='+token)
            }
            console.error(e)
        });
    }

    const changePriceState = (key, sub = null, data = null) => {
        data = data || prices;
        if (key === 'coffee') {
            let buttons = [];
            let state = {
                title: 'Кофе',
                subtitle: data[key]['americano'].title,
                price: data[key]['americano'].price / 100,
                buttons: buttons
            };
            Object.keys(data[key]).forEach((subkey, i) => {
                let title = data[key][subkey].title.replace(' 1 чашка', '');
                if (i === sub) {
                    state.id = title;
                    state.subtitle = data[key][subkey].title;
                    state.price = data[key][subkey].price / 100;
                } else {
                    if (subkey === 'americano') {
                        state.id = title
                    }
                }
                buttons.push(title)
            })

            setCoffeeState(state)
            // dispatch(setCoffeePrice(state))
        }
        if (key === 'micro') {
            let buttons = [];
            let state = {
                title: 'Аренда микрофона',
                subtitle: data[key]['1'].title,
                price: data[key]['1'].price / 100,
                buttons: buttons
            };
            Object.keys(data[key]).forEach((subkey, j) => {
                let end = subkey > 1 ? ' песни': ' песню';
                let title = 'Аренда микрофона на '+subkey+end;
                if (j === sub) {
                    state.id = title;
                    state.subtitle = title;
                    state.price = data[key][subkey].price / 100;
                } else {
                    if (subkey === '1') {
                        state.id = title;
                        state.subtitle = title;
                    }
                }
                buttons.push(title)
            })
            setMicroState(state)
            // dispatch(setMicroPrice(state))
        }
        if (key === 'rent') {
            let buttons = [];
            let state = {
                title: 'Аренда помещения',
                subtitle: data[key]['karaoke'].title,
                price: data[key]['karaoke'].price / 100,
                buttons: buttons
            };
            Object.keys(data[key]).forEach((subkey, k) => {
                let title = data[key][subkey].title.replace('Аренда помещения караоке-бара', '');
                if (k === sub) {
                    state.id = title;
                    state.subtitle = data[key][subkey].title;
                    state.price = data[key][subkey].price / 100;
                } else {
                    if (subkey === 'karaoke') {
                        state.id = title;
                    }
                }
                buttons.push(title)
            })
            setRentState(state)
            // dispatch(setRentPrice(state))
        }
    }

    return (
        <Box >
            {Object.keys(prices).map(
                (value, i) => {
                    return (
                        <Card key={i} variant="outlined" sx={{ maxWidth: '95%', margin: '3px auto'}}>
                            <Box sx={{ p: 1 }}>
                                <Stack
                                    direction="row"
                                    sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                    {/*title*/}
                                    {
                                        value === 'coffee' && <Typography gutterBottom variant="h6" component="div">{coffeeState.title}</Typography>
                                    }
                                    {
                                        value === 'rent' && <Typography gutterBottom variant="h6" component="div">{rentState.title}</Typography>
                                    }
                                    {
                                        value === 'micro' && <Typography gutterBottom variant="h6" component="div">{microState.title}</Typography>
                                    }
                                    {/*price*/}
                                    {
                                        value === 'coffee' && <Typography gutterBottom variant="h6" component="div">
                                            {coffeeState.price} <CurrencyRubleIcon sx={{ fontSize: 15 }}/>
                                        </Typography>
                                    }
                                    {
                                        value === 'rent' && <Typography gutterBottom variant="h6" component="div">
                                            {rentState.price} <CurrencyRubleIcon sx={{ fontSize: 15 }}/>
                                        </Typography>
                                    }
                                    {
                                        value === 'micro' && <Typography gutterBottom variant="h6" component="div">
                                            {microState.price} <CurrencyRubleIcon sx={{ fontSize: 15 }}/>
                                        </Typography>
                                    }

                                </Stack>
                                {/*description*/}
                                {
                                    value === 'coffee' && <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {coffeeState.subtitle}
                                    </Typography>
                                }
                                {
                                    value === 'rent' && <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {rentState.subtitle}
                                    </Typography>
                                }
                                {
                                    value === 'micro' && <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {microState.subtitle}
                                    </Typography>
                                }
                            </Box>
                            <Divider />
                            <Box sx={{ p: 2, marginBottom: '50px' }}>

                                {/*buttons*/}
                                {
                                    value === 'coffee' && <Stack spacing={1}>
                                        {coffeeState.buttons?.map((item, k) => {
                                            return <Button  key={k}
                                                            variant={coffeeState.id === item ? 'contained' : 'outlined'}
                                                            color="success"
                                                            size="small"
                                                            onClick={() => changePriceState(value, k)}
                                            >
                                                {item}
                                            </Button>
                                        })}
                                    </Stack>
                                }
                                {
                                    value === 'rent' && <Stack spacing={1}>
                                        {rentState.buttons?.map((item, l) => {
                                            return <Button
                                                key={l}
                                                variant={rentState.id === item ? 'contained' : 'outlined'}
                                                color="success"
                                                size="small"
                                                onClick={() => changePriceState(value, l)}>
                                                {item}
                                            </Button>
                                        })}
                                    </Stack>
                                }
                                {
                                    value === 'micro' && <Stack spacing={1}>
                                        {microState.buttons?.map((item, j) => {
                                            return <Button  key={j}
                                                            variant={microState.id === item ? 'contained' : 'outlined'}
                                                            color="success"
                                                            size="small"
                                                            onClick={() => changePriceState(value, j)}>{item}</Button>
                                        })}
                                    </Stack>
                                }
                            </Box>
                        </Card>)
                })
            }
        </Box>
    );
}