import { createSlice } from '@reduxjs/toolkit'

export const user = createSlice({
    name: 'user',
    initialState: {
        table: null,
        order: null,
        checked: [],
        alert: [],
        checkedSum: 0,
        token: null,
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setTable: (state, action) => {
            state.table = action.payload
        },
        setOrder: (state, action) => {
            state.order = action.payload
        },
        setChecked: (state, action) => {
            state.checked = action.payload
        },
        setAlert: (state, action) => {
            state.checked = action.payload
        },
        setCheckedSum: (state, action) => {
            state.checkedSum = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setTable,
    setToken,
    setOrder,
    setChecked,
    setAlert,
    setCheckedSum,
} = user.actions

export default user.reducer